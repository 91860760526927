<script setup>
import SpecialOrdersLayout from '@/layouts/SpecialOrdersLayout.vue';
import { onMounted, ref } from 'vue';
import useSpecialOrders from '@/hooks/useSpecialOrders';
import { useRoute } from 'vue-router';

const { fetchSpecialOrder, specialOrder } = useSpecialOrders();

const trackingNumber = ref("");
const route = useRoute();

onMounted(async () => {
  await fetchSpecialOrder(route.params.id);
})

const saveTrackingNumber = async () => {
  //handle save tracking number
}

</script>

<template>
  <SpecialOrdersLayout v-if="specialOrder" :special-order="specialOrder">
    <div class="pa-4" style="width: 600px;">
      <v-text-field label="UPS Tracking Number" variant="underlined" v-model="trackingNumber"></v-text-field>
      <v-btn @click="saveTrackingNumber" variant="outlined" color="green">Save</v-btn>
    </div>
  </SpecialOrdersLayout>
</template>
